import * as Sentry from '@sentry/vue'

import type { Plugin } from 'vue'

const sentryVuePlugin: Plugin = {
  install: (app) => {
    Sentry.init({
      enabled: import.meta.env.VITE_SENTRY_VUE_ENABLED === 'true',
      app,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dsn: import.meta.env.VITE_SENTRY_VUE_DSN ?? '',
      tracesSampleRate: Number.parseFloat(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        import.meta.env.VITE_SENTRY_VUE_TRACES_SAMPLE_RATE ?? '0.0',
      ),

      logErrors: true,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'failed',

      replaysSessionSampleRate: Number.parseFloat(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_SESSION_SAMPLE_RATE ?? '0.0',
      ),
      replaysOnErrorSampleRate: Number.parseFloat(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '1.0',
      ),

      timeout: 500,
      trackComponents: true,

      integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
    })
  },
}

export default sentryVuePlugin
